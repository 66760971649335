import ReactDOM from 'react-dom';
import * as Logger from '@crimson-education/browser-logger';
import config from './config';
import App from './App';

const isLocalhost = window.location.hostname === 'localhost';
Logger.init({
  service: 'crimson-app-web-session-notes',
  environment: config.environment,
  version: config.version,
  defaultMetadata: {
    application: 'crimson-app-web',
  },
  reporters: {
    log: true,
    datadog: config.datadogApplicationId
      ? {
          applicationId: config.datadogApplicationId,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          clientToken: config.datadogClientToken!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          site: config.datadogSite!,
          proxyUrl: config.datadogTunnelProxyUrl,
          version: config.datadogVersion,

          sampleRate: config.environment === 'production' ? 50 : 0,
          replaySampleRate: config.environment === 'production' ? 50 : 0,

          forwardConsoleLogs: !isLocalhost,
          trackInteractions: !isLocalhost,
          trackFrustrations: !isLocalhost,
          allowedTracingOrigins: [config.studentCenterAPIUrl, config.crimsonAppAPIUrl, config.roadMapAPIUrl],
        }
      : undefined,

    amplify: config.pinpointAnalyticsAppId
      ? {
          region: config.awsRegion,
          identityPoolId: config.pinpointIdentityPoolId,
          analyticsAppId: config.pinpointAnalyticsAppId,
          proxyUrl: config.pinpointProxyUrl,
          autoTrackPageViews: true,
          autoTrackEvents: true,
          autoTrackSessions: true,
        }
      : undefined,
  },
});

if (config.datadogApplicationId) {
  console.debug('Roadmap Datadog enabled');
}
if (config.pinpointAnalyticsAppId) {
  console.debug('Roadmap pinpoint enabled');
}

ReactDOM.render(<App />, document.getElementById('root'));
