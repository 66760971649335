export const firstCharUpperCase = (str: string): string => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;
};

export const convertToRGB = (hexString: string): string => {
  const hex = hexString.replace('#', '');
  if (hex.length !== 6) {
    throw 'Only six-digit hex colors are allowed.';
  }

  const aRgbHex = hex.match(/.{1,2}/g);
  if (aRgbHex && aRgbHex?.length < 3) {
    throw 'convert failed.';
  } else {
    const result = aRgbHex as unknown as string[];
    return `${parseInt(result[0], 16)}, ${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}`;
  }
};

export const getTailCharS = (num: number, staticString: string): string => {
  return num === 1 ? staticString : `${staticString}s`;
};

export const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));
