/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as rt from 'runtypes';
import { AbsoluteUri, Environment } from './basetypes';

export const Config = rt.Record({
  // Core
  environment: Environment,
  version: rt.String,
  domain: AbsoluteUri,

  // Config
  roadMapAPIUrl: AbsoluteUri,
  crimsonAppAPIUrl: AbsoluteUri,
  studentCenterAPIUrl: AbsoluteUri,

  // Datadog
  datadogApplicationId: rt.String.optional(),
  datadogClientToken: rt.String.optional(),
  datadogSite: rt.String.optional(),
  datadogTunnelProxyUrl: rt.String.optional(),
  datadogVersion: rt.String,

  // pinpoint/amplify
  awsRegion: rt.String,
  pinpointIdentityPoolId: rt.String,
  pinpointAnalyticsAppId: rt.String,
  pinpointProxyUrl: rt.String,
});
export type Config = rt.Static<typeof Config>;

export function getConfig(data: Record<string, string | undefined>): Config {
  const config: Config = {
    // Core
    environment: (data.ENVIRONMENT as Environment) ?? 'dev',
    version: data.VERSION ?? 'no-version',
    domain: data.DOMAIN as AbsoluteUri,

    // Config
    roadMapAPIUrl: data.ROADMAP_SERVICE_URL as AbsoluteUri,
    studentCenterAPIUrl: data.STUDENT_CENTER_SERVICE_URL as AbsoluteUri,
    crimsonAppAPIUrl: data.CRIMSON_APP_API_URL as AbsoluteUri,

    // Datadog
    datadogApplicationId: data.DD_APPLICATION_ID,
    datadogClientToken: data.DD_CLIENT_TOKEN,
    datadogSite: data.DD_SITE,
    datadogTunnelProxyUrl: data.DD_TUNNEL_PROXY_URL,
    datadogVersion: data.DD_VERSION ?? data.VERSION ?? 'no-version',

    // pinpoint/amplify
    awsRegion: data.AWS_REGION ?? 'ap-southeast-2',
    pinpointIdentityPoolId: data.PINPOINT_POOL_ID ?? 'ap-southeast-2:0f9e5ba0-f9c4-4219-b898-70b8b574bee1',
    pinpointAnalyticsAppId: data.PINPOINT_APP_ID ?? '1093afe7eafc4e3787b92891ec3315fa',
    pinpointProxyUrl: data.AWS_AMPLIFY_PROXY_URL ?? 'https://proxy.crimsoneducation.org/pinpoint/ap-southeast-2',
  };

  try {
    return Config.check(config);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    let message = e.message;
    if (e?.details) {
      message = JSON.stringify(e.details, null, 2);
    }
    throw new Error(`Failed to load config: ${message}`);
  }
}

export default getConfig(window.process.env);
