import { ThemeType } from 'src/context/ThemeContext';
import { convertToRGB } from 'src/utils';
import { createGlobalStyle } from 'styled-components';

// Add new color here
export enum CR_COLORS {
  LIGHT_PURPLE = '#464AC9',
  FONT_BLACK = '#1F2937',
  BACKGROUND = '#e5e5e5',
  DARK_NAVY = '#1d1e2b',
  STONE = '#73747d',
  LIGHT_INDIGO = '#4044b8',
  INDIGO = '#464ac9',
  SALMON = '#ed4b53',
  COOL_GREY = '#a9acc0',
  PALE_GREY = '#e3e7ed',
  GREEN = '#12c39a',
  NEON_GREEN = '#31e886',
  OARNGE = '#ff764c',
  YELLOW = '#fdaa02',
  MIST = '#f3f6fa',
  BABY_PINK = '#fff2ef',
  BABY_BLUE = '#f4f5ff',
  BABY_GREEN = '#edfdf4',
  PURPLE = '#6C63FF',
  BLUE = '#3B86FE',
  LIGHT_RED = '#F4948D',
  LIGHT_BLUE = '#819FEE',
  LIGHT_DARK_BLUE = '#5973B9',
  SKY_BLUE = '#4EBBFE',
  HOVER_GREY = '#f9f9ff',
  PLACE_HOLDER_GREY = '#bfbfbf',
  BAR_ORANGE = '#F3998F',
  BAR_PURPLE = '#6C63FF',
  BAR_LIGHT_PURPLE = '#6c63ff66',
  BAR_GREEN = '#35CBA8',
  BAR_BLUE = '#3B86FE',
  BAR_LIGHT_BLUE = '#3b86fe66',
  BAR_YELLOW = '#F6AF46',
}

enum FONT {
  Montserrat = 'Montserrat',
  Montserrat_Bold = 'Montserrat-Bold',
  Montserrat_SemiBold = 'Montserrat-SemiBold',
}

enum PADDINGS {
  BUTTON = '7px 28px',
  PAGE_CONTENT_LEFT = '68px',
  PAGE_CONTENT_RIGHT = '60px',
}

enum UNIT_WIDTH_HEIGHT {
  ONE_EM = '1em',
}

enum BORDERS {
  DEFAULT = '2px solid',
}

enum SHADOWS {
  DEFAULT = '0px 3px 6px',
}

// Use css variables to style your component eg: const Container = styled.div`color: var(theme-color-darknavy);`
export const GlobalStyle = createGlobalStyle<{ theme: ThemeType }>`
  :root {
    a, a:link, a:visited, a:focus, a:hover, a:active {
      color: ${CR_COLORS.INDIGO};
      text-decoration: none; 
    }
    /* color variables */
    --color-font-black: ${CR_COLORS.FONT_BLACK};
    --color-background: ${CR_COLORS.BACKGROUND};
    --color-dark-navy: ${CR_COLORS.DARK_NAVY};
    --color-stone: ${CR_COLORS.STONE};
    --color-indigo: ${CR_COLORS.INDIGO};
    --color-salmon: ${CR_COLORS.SALMON};
    --color-cool-grey: ${CR_COLORS.COOL_GREY};
    --color-pale-grey: ${CR_COLORS.PALE_GREY};
    --color-green: ${CR_COLORS.GREEN};
    --color-neon-green: ${CR_COLORS.NEON_GREEN};
    --color-orange: ${CR_COLORS.OARNGE};
    --color-yellow: ${CR_COLORS.YELLOW};
    --color-mist: ${CR_COLORS.MIST};
    --color-baby-pink: ${CR_COLORS.BABY_PINK};
    --color-baby-blue: ${CR_COLORS.BABY_BLUE};
    --color-baby-green: ${CR_COLORS.BABY_GREEN};
    --color-light-indigo: ${CR_COLORS.LIGHT_INDIGO};
    --color-purple: ${CR_COLORS.PURPLE};
    --color-light-purple: ${CR_COLORS.LIGHT_PURPLE};
    --color-blue: ${CR_COLORS.BLUE};
    --color-light-red: ${CR_COLORS.LIGHT_RED};
    --color-light-blue: ${CR_COLORS.LIGHT_BLUE};
    --color-light-dark-blue: ${CR_COLORS.LIGHT_DARK_BLUE};
    --color-sky-blue: ${CR_COLORS.SKY_BLUE};
    --color-hover-grey: ${CR_COLORS.HOVER_GREY};
    --color-placeholder-grey: ${CR_COLORS.PLACE_HOLDER_GREY};
    --color-bar-orange: ${CR_COLORS.BAR_ORANGE};
    --color-bar-purple: ${CR_COLORS.BAR_PURPLE};
    --color-bar-light-purple: ${CR_COLORS.BAR_LIGHT_PURPLE};
    --color-bar-green: ${CR_COLORS.BAR_GREEN};
    --color-bar-blue: ${CR_COLORS.BAR_BLUE};
    --color-bar-light-blue: ${CR_COLORS.BAR_LIGHT_BLUE};
    --color-bar-yellow: ${CR_COLORS.BAR_YELLOW};
    /* color-varibles-rgb-version: this can help you modified the color with opacity */
    --rgb-color-light-purple: ${convertToRGB(CR_COLORS.LIGHT_PURPLE)};
    --rgb-color-placeholder-grey: ${convertToRGB(CR_COLORS.PLACE_HOLDER_GREY)};
    --rgb-color-hover-grey: ${convertToRGB(CR_COLORS.HOVER_GREY)};
    --rgb-color-font-black: ${convertToRGB(CR_COLORS.FONT_BLACK)};
    --rgb-color-background: ${convertToRGB(CR_COLORS.BACKGROUND)};
    --rgb-color-dark-navy: ${convertToRGB(CR_COLORS.DARK_NAVY)};
    --rgb-color-stone: ${convertToRGB(CR_COLORS.STONE)};
    --rgb-color-indigo: ${convertToRGB(CR_COLORS.INDIGO)};
    --rgb-color-salmon: ${convertToRGB(CR_COLORS.SALMON)};
    --rgb-color-cool-grey: ${convertToRGB(CR_COLORS.COOL_GREY)};
    --rgb-color-pale-grey: ${convertToRGB(CR_COLORS.PALE_GREY)};
    --rgb-color-green: ${convertToRGB(CR_COLORS.GREEN)};
    --rgb-color-neon-green: ${convertToRGB(CR_COLORS.NEON_GREEN)};
    --rgb-color-orange: ${convertToRGB(CR_COLORS.OARNGE)};
    --rgb-color-yellow: ${convertToRGB(CR_COLORS.YELLOW)};
    --rgb-color-mist: ${convertToRGB(CR_COLORS.MIST)};
    --rgb-color-baby-pink: ${convertToRGB(CR_COLORS.BABY_PINK)};
    --rgb-color-baby-blue: ${convertToRGB(CR_COLORS.BABY_BLUE)};
    --rgb-color-baby-green: ${convertToRGB(CR_COLORS.BABY_GREEN)};
    --rgb-color-light-indigo: ${convertToRGB(CR_COLORS.LIGHT_INDIGO)};
    --rgb-color-purple: ${convertToRGB(CR_COLORS.PURPLE)};
    --rgb-color-blue: ${convertToRGB(CR_COLORS.BLUE)};
    --rgb-color-light-red: ${convertToRGB(CR_COLORS.LIGHT_RED)};
    --rgb-color-light-blue: ${convertToRGB(CR_COLORS.LIGHT_BLUE)};
    --rgb-color-light-dark-blue: ${convertToRGB(CR_COLORS.LIGHT_DARK_BLUE)};
    --rgb-color-sky-blue: ${convertToRGB(CR_COLORS.SKY_BLUE)};
    /* padding */
    --padding-button: ${PADDINGS.BUTTON};
    --padding-page-content-left: ${PADDINGS.PAGE_CONTENT_LEFT};
    --padding-page-content-right: ${PADDINGS.PAGE_CONTENT_RIGHT};
    /* PAGE_CONTENT_LEFT */
    /* border */
    --border-nocolor: ${BORDERS.DEFAULT};
    --border-button: ${BORDERS.DEFAULT} var(--color-indigo);
    /* box-shadow */
    --shadow-nocolor: ${SHADOWS.DEFAULT};
    --shadow-button: ${SHADOWS.DEFAULT} var(--color-light-indigo);
    :focus-visible {
      outline: 0px;
      outline-color: transparent;
      outline-width: 0px;
    }
    /* width & height */
    --unit-one-em: ${UNIT_WIDTH_HEIGHT.ONE_EM};
  }
  body {
    font-family: ${FONT.Montserrat}
  }
`;
