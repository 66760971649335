import React, { useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalStyle } from './theme';
import { ApolloClientContext } from './context/ApolloClientContext';
import AppWrapperRoute from './route/index';
import { getGraphQLClient } from './graphql';
import { getConfig } from './config';
import * as zoid from 'zoid/dist/zoid.frameworks';
import './App.less';
import { VariablesType, defaultVariables } from './context/ThemeContext';

const config = getConfig(window.process.env);
zoid.create({
  // The html tag used to render my component
  // TODO:
  tag: 'session-micro',
  // The url that will be loaded in the iframe or popup, when someone includes my component on their page
  url: config.domain,

  props: {
    token: {
      type: 'string',
      isRequired: false,
    },
    featureSwitches: {
      type: 'object',
      isRequired: false,
    },
    userId: {
      type: 'string',
      isRequired: true,
    },
    studentId: {
      type: 'string',
      isRequired: true,
    },
    authorize: {
      type: 'function',
      isRequired: true,
    },
    getBearer: {
      type: 'function',
      isRequired: false,
    },
  },
});

const RootContainer = styled.div`
  display: flex;
  /* width: 100vw; */
  flex-direction: column;
  justify-content: center;
  background: var(--color-mist);
  min-height: 100vh;
`;

const AppContainer = styled.div`
  display: flex;
  flex: 1 auto;
  padding-top: 24px;
  flex-direction: column;
  align-items: flex-start;
`;

const crimsonAppApiClient = getGraphQLClient(new URL('/graphql', config.crimsonAppAPIUrl).href, window.xprops);

const App = (): JSX.Element => {
  const [variables, setVariables] = useState<VariablesType>(defaultVariables);
  return (
    <React.Suspense fallback={null}>
      <GlobalStyle />
      <ThemeContext.Provider value={{ variables, setVariables }}>
        <ApolloClientContext.Provider value={{ crimsonAppApiClient }}>
          <RootContainer>
            <AppContainer>
              <Router>
                <AppWrapperRoute />
              </Router>
            </AppContainer>
          </RootContainer>
        </ApolloClientContext.Provider>
      </ThemeContext.Provider>
    </React.Suspense>
  );
};

export default App;
