import { notification } from 'antd';
import { match } from 'ts-pattern';

import { ApolloClient, from, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';
import type { XProps } from '../types/global';

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) => {
      return notification.error({
        message: 'GraphQL Error',
        description: `${message} operation: ${operation.operationName}`,
      });
    });
  }

  if (networkError) {
    notification.error({
      message: match(networkError)
        .when(
          (e) => 'statusCode' in e && e.statusCode === 401,
          () => 'Not Authenticated',
        )
        .when(
          (e) => 'statusCode' in e && e.statusCode === 403,
          () => 'Permission Denied',
        )
        .otherwise(() => 'Network Error'),
      description: `${networkError.message} operation: ${operation.operationName}`,
    });
    if ('statusCode' in networkError && networkError.statusCode === 403) {
      window.xprops?.authorize();
    }
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: async (error) => {
      if ('statusCode' in error && error.statusCode === 403) {
        const result = await window.xprops?.authorize();
        return !result;
      }
      return false;
    },
  },
});

const getHttpLink = (endpoint: string, xprops?: XProps) => {
  const { featureSwitches } = xprops ?? {};
  const mergedFlags = featureSwitches;
  const defaultHeaders: { [key: string]: string } = {
    'X-Feature-Switches': JSON.stringify(mergedFlags),
  };
  if (/localhost/.test(endpoint)) {
    defaultHeaders['x-user'] = JSON.stringify({
      userId: xprops?.userId ? xprops.userId : 'auth0-strategist',
      roles: xprops?.user?.role ? [{ id: xprops.user.role }] : [{ id: 'STRATEGIST' }, { id: 'STUDENT' }],
    });
  }
  return new HttpLink({
    uri: endpoint,
    headers: defaultHeaders,
  });
};

const authMiddleware = setContext(() =>
  // get token from parent frame app.
  window.xprops?.getBearer().then((token) => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }),
);

export function getGraphQLClient(endpoint: string, xprops?: XProps): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    link: from([errorLink, authMiddleware, retryLink, getHttpLink(endpoint, xprops)]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            resourceConnection: relayStylePagination(),
            tagConnection: relayStylePagination(),
          },
        },
      },
    }),
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  });
}
