import { createContext, Dispatch, SetStateAction } from 'react';

type Mode = 'light' | 'dark';

type ScreenSize = 'light' | 'dark';

export type VariablesType = {
  mode: Mode;
  screenSize: ScreenSize;
};

export type ThemeType = {
  variables: VariablesType;
  setVariables?: Dispatch<SetStateAction<VariablesType>>;
};

export const defaultVariables = {
  mode: 'light' as Mode,
  screenSize: 'large' as ScreenSize,
};

export const ThemeContext = createContext<ThemeType>({ variables: defaultVariables });
