import React from 'react';
import { Routes, Route } from 'react-router-dom';
const DefaultView = React.lazy(() => import('../pages/SessionSummary'));

export type RouteType = {
  exact: boolean;
  path: string;
  id: string;
  component: React.LazyExoticComponent<() => JSX.Element>;
};

export enum RoutePath {
  sessionSummary = '/',
}

export const routes: RouteType[] = [
  {
    exact: true,
    path: RoutePath.sessionSummary,
    id: 'default',
    component: DefaultView,
  },
];

const AppWrapperRoute: React.FC = () => {
  return (
    <Routes>
      {routes.map((route: RouteType) => (
        <Route key={route.path} path={route.path} element={<route.component data-test-id={route.id} />} />
      ))}
    </Routes>
  );
};

export default AppWrapperRoute;
